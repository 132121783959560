import { core } from "../../config/pluginInit";
import HighCharts from "highcharts";
import More from "highcharts/highcharts-more";
import HighChart from "../../components/core/charts/HighChart";
import { _ } from "vue-underscore";
More(HighCharts);
export default {
  name: "Dashboard1",
  components: {
    HighChart,
  },
  mounted() {
    core.index();
    this.getAll();
  },
  data() {
    return {
      items: [],
      charts: [
        {
          title: "Bar Chart",
          bodyData: {
            chart: {
              renderTo: "high-3d-chart",
              type: "column",
              options3d: {
                enabled: true,
                alpha: 15,
                beta: 15,
                depth: 50,
                viewDistance: 25,
              },
            },
            title: {
              text: "",
            },
            subtitle: {
              text: "",
            },
            plotOptions: {
              column: {
                depth: 25,
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                name: "Desktops",
                data: [
                  29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4,
                  194.1, 95.6, 54.4,
                ],
                color: "#0084ff",
              },
            ],
          },
        },
        {
          title: "Line Chart",
          type: "line",
          bodyData: {
            chart: {
              height: 350,
              type: "line",
              zoom: {
                enabled: false,
              },
            },
            series: [
              {
                name: "Desktops",
                data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
              },
            ],
            dataLabels: {
              enabled: false,
            },
            credits: {
              enabled: false,
            },
            stroke: {
              curve: "straight",
            },
            title: {
              text: "",
              align: "left",
            },
            grid: {
              row: {
                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
              },
            },
            xaxis: {
              categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
              ],
            },
          },
        },
      ],
    };
  },

  methods: {
    getAll() {
      const _vm = this;
      this.axios
        .get("/dashboard-company-data")
        .then(function (response) {
          _vm.items = response.data.data;
        })
        .catch(function (error) { });
    },
  },
};
